import React, { Component } from 'react';

class ChatSearchResultItem extends Component {
    constructor(props) {
      super(props);
      this.result = props.value;
      this.index = props.index;
    }
  
    componentDidMount() {
      
    }
  
    render() {
  
      return (
        <li>
            {this.result.org.length > 2 ? <p>Organization: {this.result.org}</p> : ""}
            {this.result.link.length > 2 ?  <p>Link: <a href={this.result.link} target="_blank">{this.result.link}</a></p> : ""}
            {this.result.contact.length > 2 ?  <p>Contact Name: {this.result.contact}</p> : ""}
            {this.result.email.length > 2 ?  <p>Contact Email: <a href={`mailto:${this.result.email}`} target="_blank">{this.result.email}</a></p> : ""}
            {this.result.phone.length > 2 ?  <p>Contact Number: <a href={`tel:${this.result.phone}`} target="_blank">{this.result.phone}</a></p> : ""}
        </li>
      );
    }
  }

  export default ChatSearchResultItem;