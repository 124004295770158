import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "bizhelp-miami-logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <header>
      <nav className="navbar navbar-expand-sm navbar-light">
        <Link className="navbar-brand" to="/">
          <Img
            loading="eager"
            fluid={data.logo.childImageSharp.fluid}
            alt="BizHelp Miami Logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/sba-approved-lenders">
                Approved Lenders
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="navbar-ppp"
                className="nav-link"
                to="/ppp-access"
              >
                Funding Resources
              </Link>
              <div className="dropdown-menu" aria-labelledby="navbar-ppp">
                <span className="dropdown-item">
                  <Link to="/additional-funding-state-and-federal">
                          State &amp; Federal Loans
                  </Link>
                </span>
                <span className="dropdown-item">
                  <Link to="additional-funding-foundations-grants-nonprofits">
                    Foundations, Grants &amp; Non-Profits
                  </Link>
                </span>
              </div>
            </li>
            <li className="nav-item dropdown">
              <Link
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="navbar-bta"
                className="nav-link"
                to="/business-technical-assistance"
              >
                Business Technical Assistance
              </Link>
              <div className="dropdown-menu" aria-labelledby="navbar-bta">
                <span className="dropdown-item">
                  <Link to="/business-assistance">
                    Business Technical Assistance
                  </Link>
                  <ul>
                    <li>
                      <Link to="/cares-act-and-other-loan-prep">
                        CARES Act and Other Loan Prep
                      </Link>
                    </li>
                    <li>
                      <Link to="/growth-marketing">Growth Marketing</Link>
                    </li>
                    <li>
                      <Link to="/international-business">
                        International Business
                      </Link>
                    </li>
                    <li>
                      <Link to="/micro-bridge-funding">
                        Micro Bridge Funding
                      </Link>
                    </li>
                    <li>
                      <Link to="/private-partners">
                        Private Pro Bono Partners
                      </Link>
                    </li>
                    <li>
                      <Link to="/procurement">Procurement</Link>
                    </li>
                    <li>
                      <Link to="/technology-maximization">
                        Technology Maximization
                      </Link>
                    </li>
                  </ul>
                </span>
              </div>
            </li>
            <li className="nav-item dropdown">
              <Link
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="navbar-rs"
                className="nav-link"
                to="/resource-support"
              >
                Resources &amp; Support
              </Link>
              <div className="dropdown-menu" aria-labelledby="navbar-ppp">
                <span className="dropdown-item">
                  <Link to="/international-business-and-non-resident">
                    International Business &amp; Non-resident Resources
                  </Link>
                  <ul>
                    <li>
                      <Link to="/specialized-services">
                        Specialized Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/immigration-attorneys">
                        Immigration Attorneys
                      </Link>
                    </li>
                  </ul>
                </span>
                <span className="dropdown-item">
                  <Link to="/individual-workforce">
                    Individual Workforce Support
                  </Link>
                </span>
                <span className="dropdown-item">
                  <Link to="/resource-partners">Resource Partners</Link>
                </span>
              </div>
            </li>
            <li className="nav-item">
              <Link
                id="navbar-about"
                className="nav-link"
                to="/about"
              >
                About
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
