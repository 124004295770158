import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLinkedin,
  faFacebookSquare,
  faTwitterSquare,
  faYoutubeSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query SiteImagesQuery {
      logo: file(relativePath: { eq: "beacon-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gmcvblogo: file(relativePath: { eq: "GMCVB_Corp_Logo_BLUE_HR.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slalomLogo: file(relativePath: { eq: "slalom-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oneCommunityLogo: file(relativePath: { eq: "one-community.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miamiLogo: file(relativePath: { eq: "miami-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
       covidLogo: file(relativePath: { eq: "covid-resource-center.png" }) {
         childImageSharp {
           fluid(maxWidth: 880) {
             ...GatsbyImageSharpFluid
           }
         }
       }
    }
  `)

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col justify-content-center">
            <hr />
            <span className="text-muted">
              <strong>
                <a
                  href="https://www.beaconcouncil.com/wp-content/uploads/2020/04/FAQ_MDBC_PAYCHECK-PROTECTION-PROGRAM_04.08.2020.pdf"
                  target="_blank"
                >
                  {" "}
                  Download the Paycheck Protection Program FAQ.
                </a>
              </strong>
               <br />
               <br />
               <a
                 href="https://www.beaconcouncil.com/solutions/small-business/covid-19-updates-and-resources/"
                 target="_blank"
               >
                 <Img className="banner" fluid={data.covidLogo.childImageSharp.fluid} alt="Covid 19 Updates" />
               </a>
              <br />
              <strong>Need Help? Contact Us</strong>
              <p>
                The Miami-Dade Beacon Council and our partners invite you to contact us with questions, feedback or requests for additional support. Email &nbsp;
                <a href="mailto:questions@BizHelp.Miami">
                  questions@BizHelp.Miami
                </a>
                &nbsp;or call Miami-Dade County Small Business Development Office for assitance: <a href="tel:3053753111">(305) 375-3111</a>
              </p>
              <a
                href="https://www.beaconcouncil.com/terms-conditions/"
                target="_blank"
              >
                <small>Terms &amp; Conditions</small>
              </a>
            </span>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col pull-right social-container">
            <a href="https://www.beaconcouncil.com" target="_blank">
              <Img
                className="footerLogo"
                fluid={data.logo.childImageSharp.fluid}
                alt="BizHelp Miami"
              />
            </a>
            <a href="https://www.miamiandbeaches.com/miamishines" target="_blank">
              <Img
                className="footerLogo"
                fluid={data.gmcvblogo.childImageSharp.fluid}
                alt="BizHelp Miami"
              />
            </a>
            <a href="https://www.slalom.com" target="_blank">
              <Img
                className="footerLogo"
                fluid={data.slalomLogo.childImageSharp.fluid}
                alt="BizHelp Miami"
              />
            </a>
            <a href="http://www.miamidade.gov/global/home.page" target="_blank">
              <Img
                className="footerLogo"
                fluid={data.miamiLogo.childImageSharp.fluid}
                alt="BizHelp Miami"
              />
            </a>
            <a href="https://www.beaconcouncil.com/ocog" target="_blank">
              <Img
                className="footerLogo"
                fluid={data.oneCommunityLogo.childImageSharp.fluid}
                alt="BizHelp Miami"
              />
            </a>
          </div>
        </div>
        {/* <div className="row">
            <div className="col pull-right social-container">
              <a
                href="https://www.linkedin.com/company/the-beacon-council/"
                target="_blank"
              >
                <FontAwesomeIcon
                  size="2x"
                  color="#505050"
                  icon={faLinkedin}
                />
              </a>
              <a
                href="https://www.facebook.com/beaconcouncil/"
                target="_blank"
              >
                <FontAwesomeIcon
                  size="2x"
                  color="#505050"
                  icon={faFacebookSquare}
                />
              </a>
              <a href="https://twitter.com/beaconcouncil" target="_blank">
                <FontAwesomeIcon
                  size="2x"
                  color="#505050"
                  icon={faTwitterSquare}
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCiSBUS0CUesr_fxB1957zxA/featured?view_as=subscriber"
                target="_blank"
              >
                <FontAwesomeIcon
                  size="2x"
                  color="#505050"
                  icon={faYoutubeSquare}
                />
              </a>
              <a
                href="https://www.instagram.com/beaconcouncil/"
                target="_blank"
              >
                <FontAwesomeIcon
                  size="2x"
                  color="#505050"
                  icon={faInstagramSquare}
                />
              </a>
            </div>
          </div> */}
      </div>
    </footer>
  )
}
export default Footer
