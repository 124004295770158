import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Interactions, Predictions } from 'aws-amplify'
import { Loading } from 'react-simple-chatbot';
import ChatSearchResultList from './chatsearchresultlist';

class ChatSearchContainer extends Component {
    constructor(props) {
      super(props);
      this.lang = props.lang;

      this.state = {
        loading: true,
        result: '',
        trigger: false,
        hidden: false
      };
  
      this.triggetNext = this.triggetNext.bind(this);
      this.props.step.replace = false;
    }
  
    parseOutput(output) {
      let outputRegex = /Organization :(.*?) Link:(.*?) Contact Name:(.*?) Contact Email:(.*?) Contact Number:(.*?)$/;
      let parts = output.replace(/\n|\r/g, "").split("*");
      if(parts.length <= 1)
        return null;
      
      let results = []; 
      for(let i = 1; i < parts.length; i++){
        let matches = outputRegex.exec(parts[i].trim());
        let phone = matches[5].trim().replace(/\D/g,'');
        if(phone.length == 10){
          phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }
        let result = {
          org: matches[1].trim(),
          link: matches[2].trim(),
          contact: matches[3].trim(),
          email: matches[4].trim(),
          phone: phone
        }
        results.push(result);
        
      }
      return results;
    }

    componentDidMount() {
      const self = this;
      const { steps, lang } = this.props;
      const search = (lang === "Spanish" ? steps.searchSpanish.value : steps.search.value);
      if(lang === "Spanish"){
        Predictions.convert({
          translateText: {
            source: {
              text: search,              
            },
          }
        })
        .then(result => {
          self.setOutput(result.text);
        })
        .catch(err => {
          self.handleError(null, null);
        });
      }
      else{
        self.setOutput(search);
      }
    }
    
    setOutput(input){
      const self = this;
      Interactions.send("bc_chatbot", input).then(
        (response) => {
            if (response.dialogState === "Fulfilled") {
              let output = self.parseOutput(response.message);
              if(output != null){
                self.setState({ loading: false, result: output});
                this.props.resetErrorCount();
              }
              else{
                self.handleError(response.slots, null);
              }
            } else {
              self.handleError(null, response.message);
            }
        }).catch((error) => {
          self.handleError(null, null);
        });
    }

    handleError(slots, errorMessage){
      const { lang } = this.props;
      this.props.incrementErrorCount();

      if(this.props.getErrorCount() >= 3){
        this.setState({ loading: false, result: "", hidden: true});
        this.props.step.replace = true;
        this.props.triggerNextStep({
          value: 0,
          trigger: (lang ===  "Spanish" ? "3strikesSpanish" : "3strikes")
        })
        return;
      }

      if(slots != null){
        let item;
        let code = `${slots.ind != null ? 1:0}${slots.occ != null ? 1:0}${slots.business != null ? 1:0}`;
        let conjuction = (lang === "Spanish" ? "o la" : "or");
        switch(code){
          case "111":
            item = `${slots.ind}, ${slots.occ}, ${conjuction} ${slots.business}`;
            break;
          case "110":
            item = `${slots.ind} ${conjuction} ${slots.occ}`;
            break;
          case "101":
            item = `${slots.ind} ${conjuction} ${slots.business}`;
            break;
          case "100":
            item = slots.ind
            break;
          case "011":
            item = `${slots.occ} ${conjuction} ${slots.business}`;
            break;
          case "010":
            item = slots.occ;
            break;
          case "001":
            item = slots.business;
            break;
          default:
            item = (lang === "Spanish" ? "que estabas buscando" : "what you were looking for");
            break;
        }
        
        let message;

        if(lang === "Spanish")
          message = `No encuentro la ${item}. Puedes tratar una frase diferente?`
        else
          message = `I couldn't find ${item}. Can you please try a different phrase?`

        this.setState({ loading: false, result: message});
        this.props.step.replace = true;
        this.props.triggerNextStep({
          value: message,
          trigger: (lang ===  "Spanish" ? "searchErrorSpanish" : "searchError")
        });
        return;
      }

      if(errorMessage != null && !errorMessage.includes("questions")){
        this.setState({ 
          loading: false, 
          result: lang ===  "Spanish" ? 'Lo siento, me puedes repetir eso?' : errorMessage 
        });

        this.props.step.replace = true;
        this.props.triggerNextStep({
          value: this.state.result,
          trigger: (lang ===  "Spanish" ? "searchErrorSpanish" : "searchError")
        });
        return;
      }

      this.setState({
        loading: false, 
        result: lang ===  "Spanish" ? 'Lo siento, me puedes repetir eso?' : 'Sorry, can you please repeat that?'
      });

      this.props.step.replace = true;
        this.props.triggerNextStep({
          value: lang ===  "Spanish" ? 'Lo siento, me puedes repetir eso?' : 'Sorry, can you please repeat that?',
          trigger: (lang ===  "Spanish" ? "searchErrorSpanish" : "searchError")
        });
      return;

    }

    answerFail(){
      const { steps, lang } = this.props;
      const search = (lang === "Spanish" ? steps.searchSpanish.value : steps.search.value);
      this.props.responseNotHelpful(search);
      this.setState({ trigger: true }, () => {
        this.props.triggerNextStep({
          value: lang ===  "Spanish" ? 'Lo siento, me puedes repetir eso?' : 'Sorry, can you please repeat that?',
          trigger: (lang ===  "Spanish" ? "answerUnhelpfulSpanish" : "answerUnhelpful")
        });
      });
      
    }

    triggetNext() {
      this.setState({ trigger: true }, () => {
        this.props.triggerNextStep();
      });
    }
  
    render() {
      const { trigger, loading, result, hidden } = this.state;
  
      return (
        <div className="search_container" style={{display: !hidden ? "block": "none !important"}}>
          { loading ? <Loading /> : <ChatSearchResultList value={result} lang={this.lang} /> }
          {
            !loading &&
            <div
              style={{
                textAlign: 'center',
                marginTop: 20,
              }}
            >
              <p>{this.lang === "Spanish" ? "¿Te resultó útil esta respuesta" : "Was this answer helpful?"}</p>
              {
                !trigger && (this.props.getErrorCount() < 3) &&
                <button className="btn btn-chat btn-margin"
                  onClick={() => this.triggetNext()}
                >
                  {this.lang === "Spanish" ? "Si" : "Yes"}
                </button>
              }
              {
                !trigger && (this.props.getErrorCount() < 3) &&
                <button className="btn btn-chat"
                  onClick={() => this.answerFail()}
                >
                  {this.lang === "Spanish" ? "No" : "No"}
                </button>
              }
            </div>
          }
        </div>
      );
    }
  }
  
  ChatSearchContainer.propTypes = {
    steps: PropTypes.object,
    triggerNextStep: PropTypes.func,
  };
  
  ChatSearchContainer.defaultProps = {
    steps: undefined,
    triggerNextStep: undefined,
  };

  export default ChatSearchContainer;