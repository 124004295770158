/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { CookiesProvider } from 'react-cookie';
import Footer from "./footer"
import Header from "./header"
import BizHelpChatbot from "./BizHelpChatbot/bizhelpchatbot"

// import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      logo: file(relativePath: { eq: "beacon-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slalomLogo: file(relativePath: { eq: "slalom-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oneCommunityLogo: file(relativePath: { eq: "one-community.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miamiLogo: file(relativePath: { eq: "miami-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <CookiesProvider>
          <main>{children}</main>
        </CookiesProvider>
        <CookieConsent
          location="bottom"
          buttonText="OK, Thank You"
          cookieName="bizHelpMiamiConsent"
          buttonStyle={{ background: "#1E629C", color: "#ffffff" }}
        >
          By continuing to use this website, you consent to the use of cookies
          in accordance with our&nbsp;
          <Link className="privacyPolicy" to="/privacy-policy">
            Privacy Policy.
          </Link>
        </CookieConsent>
        <BizHelpChatbot />
       <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
