import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import { useState } from "react";
import ChatSearchContaner from './chatsearchcontainer';
import Personas from './personas';
import ReactGA from 'react-ga';
import './bizhelpchatbot.css';

class BizHelpChatbot extends Component {
  constructor(props) {
    super(props);

    let persona = Personas[Math.floor(Math.random()*Personas.length)];

    this.defaultTop = 130;
    this.defaultHeight = 520;
    let height = this.defaultHeight;
    if (typeof window !== 'undefined')
      height = Math.min((window.innerHeight - this.defaultTop), this.defaultHeight);

    console.log(height);
    this.state = {
      errorCount: 0,
      openCount: 0,
      opened: false,
      top: this.defaultTop, 
      height: height,
      persona: persona,
      chatbotKey: Math.floor(Math.random()*10000)
    };

    ReactGA.initialize('UA-162931887-1');
    let greeting =  `Hello! My name is ${persona.name}.  What can I help you find?  You can ask me things like “I need help finding a lawyer” OR “Help me find funding”`;
    let greetingSpanish = `Hola, mi nombre es ${persona.name}. En que le puedo asistir? Me puede preguntar cosas como “Necesito encontrar un abogado” O “Ayudeme a encontrar financiamiento”`;
    
    this.steps =
    [
      {
        id: '1',
        message: 'Hello! I can help you find local organizations to help with your needs. Would you like to continue in English or Español?',
        trigger: 'lang'
      },
      {
        id: 'lang',
        options: [
          { value: 'eng', label: 'English', trigger: '3' },
          { value: 'esp', label: 'Español', trigger: '4' },
        ],
      },
      {
        id: '3',
        message: greeting,
        trigger: 'search'
      },
      {
        id: '4',
        message: greetingSpanish,
        trigger: 'searchSpanish'
      },
      {
        id: 'search',
        user: true,
        trigger: 'results'
      },
      {
        id: 'results',
        component: <ChatSearchContaner 
        lang={"Engligh"} 
        incrementErrorCount = {this.incrementErrorCount.bind(this)} 
        getErrorCount = {this.getErrorCount.bind(this)} 
        resetErrorCount = {this.resetErrorCount.bind(this)}
        responseNotHelpful = {this.responseNotHelpful.bind(this)}/>,
        waitAction: true,
        trigger: '5',
      },
      {
        id: '5',
        message: 'What else can I help you find?  You can ask me things like “I need help finding a lawyer” OR “Help me find funding”',
        trigger: 'search'
      },
      {
        id: 'searchError',
        message: ({ previousValue, steps }) => previousValue,
        trigger: 'search'
      },
      {
        id: 'searchErrorSpanish',
        message: ({ previousValue, steps }) => previousValue,
        trigger: 'searchSpanish'
      },
      {
        id: 'searchSpanish',
        user: true,
        trigger: 'resultsSpanish'
      },
      {
        id: 'resultsSpanish',
        component: <ChatSearchContaner 
        lang={"Spanish"} 
        incrementErrorCount = {this.incrementErrorCount.bind(this)} 
        getErrorCount = {this.getErrorCount.bind(this)} 
        resetErrorCount = {this.resetErrorCount.bind(this)}
        responseNotHelpful = {this.responseNotHelpful.bind(this)}/>,
        waitAction: true,
        trigger: '6',
      },
      {
        id: '6',
        message: 'En que le puedo asistir? Me puede preguntar cosas como “Necesito encontrar un abogado” O “Ayudeme a encontrar financiamiento”',
        trigger: 'searchSpanish'
      },
      {
        id: '3strikes',
        component: (<p className="three-strikes">Sorry, I could not understand. Please email Beacon Council at <a href="mailto:questions@beaconcouncil.com">questions@beaconcouncil.com</a></p>),
        asMessage: true,
        end: true
      },
      {
        id: '3strikesSpanish',
        component: (<p className="three-strikes">Lo siento, no lo entendi. Favor contactar al Beacon Council en <a href="mailto:questions@beaconcouncil.com">questions@beaconcouncil.com</a></p>),
        asMessage: true,
        end: true
      },
      {
        id: 'answerUnhelpful',
        component: (<p className="three-strikes">Sorry, I could not find the answer you were looking for. Please email Beacon Council at <a href="mailto:questions@beaconcouncil.com">questions@beaconcouncil.com</a></p>),
        asMessage: true,
        end: true
      },
      {
        id: 'answerUnhelpfulSpanish',
        component: (<p className="three-strikes">Lo siento, no pude encontrar la respuesta que buscabas. Favor contactar al Beacon Council en <a href="mailto:questions@beaconcouncil.com">questions@beaconcouncil.com</a></p>),
        asMessage: true,
        end: true
      }
    ];


    this.theme = {
      background: '#f5f8fb',
      headerBgColor: '#1c65a1',
      headerFontColor: '#fff',
      botBubbleColor: '#1c65a1',
      botFontColor: '#fff',
      userBubbleColor: '#9fb740',
      userFontColor: '#000000'
    }

    this.incrementErrorCount.bind(this);
    this.getErrorCount.bind(this);
    this.resetErrorCount.bind(this);
    this.responseNotHelpful.bind(this);

    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions.bind(this));
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    updateDimensions(){
      let height = Math.min((window.innerHeight - this.defaultTop), this.defaultHeight);
      this.setState({height: height});
    }

    resetErrorCount(){
      this.setState({errorCount: 0});
    }

    incrementErrorCount(){
      this.setState({errorCount: this.state.errorCount + 1});
    }
    
    getErrorCount(){
      return this.state.errorCount;
    }

    responseNotHelpful(results){
      ReactGA.exception({
        description: `A user found the results from the search "${results}" to not be helpful.`,
        fatal: false
      });
    }

    toggleOpened(){
      this.setState({opened: !this.state.opened}, () => {
        if(this.state.opened){
          console.log('Opened');
          this.setState({chatbotKey: Math.floor(Math.random()*10000)});
          if(this.state.openCount == 0){
            ReactGA.event({
              category: 'Chatbot',
              action: `Chatbot Opened by User on ${window.location.pathname}`,
              label: `Chatbot persona is ${this.state.persona.name}`
            });
            this.setState({openCount: 1});
          }
        }
        else{
          this.setState({errorCount: 0});
          console.log('Closed');
        }
      })
    }

    handleEnd({ steps, values }) {
      this.setState({errorCount: 0});
    }

    render(){
      return (
        <ThemeProvider theme={this.theme} key={this.state.chatbotKey}>
          <ChatBot
          steps={this.steps}
          floating={true}
          botAvatar={this.state.persona.img}
          bubbleStyle = {{maxWidth: "55%"}}
          handleEnd = {this.handleEnd.bind(this)}
          floatingStyle={{ 
            top: `${this.state.top}px`, 
            right: '0px', 
            bottom: '0px',
            height: '150px',
            width: '40px',
            transformOrigin: 'right top',
            borderRadius: '0% 0% 0% 0%'
          }}
          opened={this.state.opened}
          floatingIcon = {(<p className="lets-chat">Let's Chat!</p>)}
          width = {"450px"}
          height = {`${this.state.height}px`}
          toggleFloating = {this.toggleOpened.bind(this)}
          />
          </ThemeProvider>
      );
    }
}

export default BizHelpChatbot;