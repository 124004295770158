import React, { Component } from 'react';
import ChatSearchResultItem from './chatsearchresultitem'

class ChatSearchResultList extends Component {
    constructor(props) {
      super(props);
      this.results = props.value;
      this.lang = props.lang;
      this.state = {
        fullList: false
      };
    }
    
    showFullList(){
      this.setState({fullList: !this.state.fullList});
    }
    render() {
      if(Array.isArray(this.results)){
        return (
          <div className="search_results">
            <p>{this.lang === "Spanish" ? "Aqui algunos resultados: ": "Here are some results"}</p>
            <ol>
              {this.results.map((result, index) => {
                if(index > 2){
                  return (
                  <div style={{display: this.state.fullList ? "block": "none"}} key={index}>
                  <ChatSearchResultItem value={result} count={index}/>
                  </div>
                  );
                }
                return <ChatSearchResultItem value={result} count={index} key={index}/>;
              })}
            </ol>
            <div
              style={{
                textAlign: 'center',
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              {
                (this.results.length > 3) && !this.state.fullList &&
                <button className="btn btn-chat"
                  onClick={() => this.showFullList()}
                >
                  {this.lang === "Spanish" ? "Oprima aqui para mas resultados": "Click here for more results"}
                </button>
              }
            </div>
        </div>
        )
      }
      
      return (
        <div className="search_results">
            <p>{this.results}</p>
        </div>
      );
    }
  }

  export default ChatSearchResultList;